import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  border-bottom: 1px solid #ebebeb;
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 4px;

  &.caution-item {
    .info-txt {
      width: 100%;
      position: relative;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 12px;
        padding-left: 1px;
        font-weight: 600;
        height: 12px;
        top: -3px;
        right: 6px;
        border-radius: 100vh;
        border: solid 1px #00b5a6;
        font-size: 10px;
        text-align: center;
        color: #00b5a6;
      }

      &:after {
        display: block;
        opacity: 0;
        position: absolute;
        top: -27px;
        left: 77%;
        content: attr(data-text);
        width: max-content;
        font-weight: 300;
        padding: 5px;
        border-radius: 5px;
        font-size: 14px;
        color: #ffffff;
        background: rgba(0, 0, 0, 0);
        z-index: 10;
        transition: 0.4s;
      }

      &:hover {
        transition: 0.2s;

        &:after {
          opacity: 1;
          background: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }

  &.disabled-item {
    font-weight: 400;
    color: #dadce0;
  }

  ${BreakPoints.medium} {
    font-weight: 400;
    word-break: break-all;
    font-size: 12px;

    &.caution-item {
      .info-txt {
        width: 100%;
        position: relative;

        span {
          display: none;
        }
      }
    }
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** 機能名 */
  label?: string
  /** 説明 */
  info?: string
  /** 内容 */
  children?: ReactNode
}

/**
 * プラン用機能1つ分コンポーネント
 * @param props
 * @constructor
 */
const OfficialPlanItem = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={className}>
      {props.info ? (
        <div className="info-txt" data-text={props.info}>
          {props.label}
          <span>i</span>
        </div>
      ) : (
        <>
          {!props.label ? (
            <div>{props.children}</div>
          ) : (
            <div>{props.label}</div>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default OfficialPlanItem
