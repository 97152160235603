import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import CommonH3 from '../atoms/commonH3'
import OfficialPlanEnterpriseDetail from '../atoms/officialPlanEnterpriseDetail'
import OfficialPlanDetail from '../atoms/officialPlanIDetail'
import OfficialPlanName from '../atoms/officialPlanIName'
import OfficialPlanItem from '../atoms/officialPlanItem'
import PlanFunctionType from '../atoms/planFunctionType'
import OfficialPlanMoreList from '../molecules/officialPlanIMoreList'
import OfficialPlanOptionList from '../molecules/officialPlanOptionList'

const Wrapper = styled.section`
  width: 100%;

  .p-section-inner,
  .p-more-wrap {
    max-width: 1090px;
    display: flex;
    justify-content: space-between;
    margin: 80px auto 0;
    padding: 20px 20px 0;
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;

    .p-section-plan {
      .p-function-wrap {
        display: flex;
        align-items: flex-end;

        h3 {
          display: block;
          font-size: 18px;
          margin: 0;
          width: 100%;

          span {
            display: block;
            margin: 0;
            padding: 0 0 5px;

            &:before {
              width: 20%;
            }
          }
        }

        // 高さを合わせるためのdiv
        &.wrap-01 {
          height: 370px;
          width: 230px;
        }

        &.wrap-02 {
          height: 100px;
        }

        &.wrap-03 {
          height: 92px;
        }
      }

      border-right: dotted 1px #e9e9e9;

      &.plan03 {
        .price-txt {
          margin-bottom: 36px;

          span {
            display: none;
          }
        }

        .txt-color-label {
          height: 0;
          overflow: hidden;
          margin: 16px auto 15px;
        }
      }

      .p-plan-list {
        padding: 0 4px 0 0;
        margin: 0 0 0 4px;
      }

      &.plan01,
      &.plan02,
      &.plan03 {
        .p-plan-list {
          border-top: solid 2px #e5e5e5;
        }
      }
    }
  }

  .p-more-wrap {
    margin: 0 auto 80px;
    padding: 0 20px 10px;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    transition: 0.4s;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .annotation-wrap {
    margin-top: 10px;
    font-size: 14px;
    width: 100%;
    text-align: start;
  }

  ${BreakPoints.xLarge} {
    .p-section-inner,
    .p-more-wrap {
      .p-section-plan {
        .p-function-wrap {
          h3 {
            span {
              &:before {
              }
            }
          }

          // 高さを合わせるためのdiv
          &.wrap-01 {
            height: 430px;
          }

          &.wrap-02 {
          }

          &.wrap-03 {
          }
        }

        &.plan03 {
          .price-txt {
            span {
            }
          }

          .txt-color-label {
          }
        }

        .p-plan-list {
        }

        &.plan01,
        &.plan02,
        &.plan03 {
          .p-plan-list {
          }
        }
      }
    }

    .p-more-wrap {
    }

    @media screen and (max-width: 920px) {
      .p-section-inner,
      .p-more-wrap {
        .p-section-plan {
          .p-function-wrap {
            // 高さを合わせるためのdiv
            &.wrap-01 {
              height: 430px;
            }
          }
        }
      }
    }
  }

  ${BreakPoints.large} {
    .p-section-inner,
    .p-more-wrap {
      justify-content: center;

      .p-section-plan {
        .p-function-wrap {
          h3 {
            font-size: 14px;

            span {
              &:before {
              }
            }
          }

          // 高さを合わせるためのdiv
          &.wrap-01 {
            width: 170px;
            height: 180px;
          }

          &.wrap-02 {
          }

          &.wrap-03 {
          }
        }

        &.plan03 {
          .price-txt {
            span {
            }
          }

          .txt-color-label {
          }
        }

        .p-plan-list {
        }

        &.plan01,
        &.plan02,
        &.plan03 {
          width: calc(90vw / 4);

          .p-plan-list {
          }
        }
      }
    }

    .p-more-wrap {
    }

    .annotation-wrap {
      font-size: 12px;
      margin: 0 15px;
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner,
    .p-more-wrap {
      justify-content: center;
      //padding: 10px 10px 0;

      .p-section-plan {
        .p-function-wrap {
          h3 {
            span {
              &:before {
              }
            }
          }

          // 高さを合わせるためのdiv
          &.wrap-01 {
            min-width: 110px;
            width: calc(90vw / 4);
          }

          &.wrap-02 {
          }

          &.wrap-03 {
          }
        }

        &.plan03 {
          .price-txt {
            span {
            }
          }

          .txt-color-label {
          }
        }

        .p-plan-list {
        }

        &.plan01,
        &.plan02,
        &.plan03 {
          width: calc(90vw / 4);

          .p-plan-list {
          }
        }
      }
    }

    .p-more-wrap {
    }
  }

  ${BreakPoints.small} {
    .p-section-inner,
    .p-more-wrap {
      justify-content: center;
      //padding: 10px 10px 0;

      .p-section-plan {
        .p-function-wrap {
          // 高さを合わせるためのdiv
          &.wrap-01 {
            min-width: 110px !important;
            width: calc(90vw / 4) !important;
            height: 150px;
          }
        }

        .p-plan-list {
          // 高さを合わせるためのdiv
          &.wrap-01 {
            min-width: 110px !important;
            width: calc(90vw / 4) !important;
            height: 150px;
            margin: 0;
          }
        }

        &.plan01,
        &.plan02,
        &.plan03 {
          width: calc(90vw / 4);
        }
      }
    }
  }
`

/**
 * サービスサイト料金-プラン表
 * @constructor
 */
const OfficialLowPlan01 = () => (
  <Wrapper>
    <div className="p-section-inner">
      {/** 機能名 */}
      <div className="p-section-plan">
        <div className="p-function-wrap wrap-01">
          <CommonH3 label=" " />
        </div>
        <div className="p-plan-list wrap-01">
          <OfficialPlanItem label="利用可能人数" />
          <OfficialPlanItem label="PC/スマホでの打刻" />
          <OfficialPlanItem label="残業/休暇申請" />
          <OfficialPlanItem label="オリジナルシフト" />
          <OfficialPlanItem label="客先勤務表対応" className="caution-item" />
          <OfficialPlanItem label="交通費精算" className="caution-item" />
          <OfficialPlanItem label="36協定の残業管理" className="caution-item" />
          <OfficialPlanItem
            label="年5日の有給取得管理"
            className="caution-item"
          />
          <OfficialPlanItem label="出勤者管理" className="caution-item" />
          <OfficialPlanItem label="勤務実態集計" className="caution-item" />
          <OfficialPlanItem label="システム通知" className="caution-item" />
          <OfficialPlanItem label="管理アラート" className="caution-item" />
          <OfficialPlanItem
            label="従業員へのお知らせ"
            className="caution-item"
          />
          <OfficialPlanItem label="組織管理" className="caution-item" />
          <OfficialPlanItem label="承認ルール設定" className="caution-item" />
          <OfficialPlanItem label="給与ソフト連携" className="caution-item" />
          <OfficialPlanItem className="caution-item">
            <a href="#modal-bnr01">代理申請</a>
          </OfficialPlanItem>
          <OfficialPlanItem label="機能カスタマイズ" className="caution-item" />
        </div>
      </div>
      {/** フリー */}
      <div className="p-section-plan plan03">
        <OfficialPlanName label="Free" className="plan-free" />
        <OfficialPlanDetail
          price="無料"
          description="スタートアップ・特定のグループ内で従業員向け勤怠機能を利用したい場合におすすめのプランです"
        />
        <div className="p-plan-list">
          <PlanFunctionType info="20名まで" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="当日分を表示" />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="最大5グループ" />
          <PlanFunctionType info="最大2種/2段階" />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="－" />
        </div>
      </div>
      {/** ビジネス */}
      <div className="p-section-plan plan02">
        <OfficialPlanName label="Business" className="plan-business" />
        <OfficialPlanDetail
          price="150"
          description="管理職向け機能も活用し、勤怠業務効率を改善されたい中・小規模の企業におすすめのプランです"
        />
        <div className="p-plan-list">
          <PlanFunctionType info="100名まで" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="最大5年分を表示" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="最大15グループ" />
          <PlanFunctionType info="最大3段階" />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="－" />
        </div>
      </div>
      {/** エンタープライズ */}
      <div className="p-section-plan plan01">
        <OfficialPlanName label="Enterprise" className="plan-enterprise" />
        <OfficialPlanEnterpriseDetail
          price="300"
          description="キンクラをフル活用されたい大規模企業または自社独自の機能をご利用されたい場合におすすめのプランです"
        />
        <div className="p-plan-list">
          <PlanFunctionType info="無制限" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="最大5段階" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
        </div>
      </div>
    </div>
    <div className="p-more-wrap">
      <OfficialPlanOptionList />
      <OfficialPlanMoreList />
    </div>
  </Wrapper>
)

export default OfficialLowPlan01
