import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  width: 100%;
  height: 58px;
  font-size: 28px;
  font-weight: 600;
  color: #454f63;
  padding: 0 15px;
  &.plan-enterprise {
    background: #ffd167;
  }
  &.plan-business {
    background: #ffebb5;
  }
  &.plan-free {
    background: #f1f1f1;
  }
  ${BreakPoints.medium} {
    font-size: 24px;
  }
  ${BreakPoints.small} {
    font-size: 18px;
    word-break: break-all;
    line-height: 1em;
    padding: 0 5px;
  }
`

type Props = {
  /** クラス名 */
  className: string
  /** ラベル(プラン名) */
  label: string
}

/**
 * プラン名コンポーネント
 * @param props
 * @constructor
 */
const OfficialPlanName = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={`${className} plan-name low`}>{props.label}</Wrapper>
  )
}

export default OfficialPlanName
