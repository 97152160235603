import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  border-bottom: 1px solid #ebebeb;
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 4px;
  font-weight: 600;
  color: #454f63;
  justify-content: center;

  img {
    width: 23px;
    height: auto;
  }

  .notes {
    font-size: 12px;
    margin-left: 3px;
    margin-top: -5px;
  }

  ${BreakPoints.medium} {
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** 機能名 */
  mark?: boolean
  /** 説明 */
  info?: string
  /** 注釈フラグ */
  notes?: boolean
  /** 注釈内容 */
  notesLabel?: string
  /** 子要素 */
  children?: ReactNode
}

/**
 * プラン用機能1つ分コンポーネント
 * @param props
 * @constructor
 */
const PlanFunctionType = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={className}>
      {props.mark ? (
        <img src="/images/official/common/imgCheck01.png" alt="利用可能機能" />
      ) : (
        props.info
      )}
      {props.notes && <p className="notes">{props.notesLabel}</p>}
      {props.children}
    </Wrapper>
  )
}

export default PlanFunctionType
