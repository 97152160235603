import React from 'react'
import CommonH3 from '../atoms/commonH3'
import OfficialPlanItem from '../atoms/officialPlanItem'
import PlanFunctionType from '../atoms/planFunctionType'

/**
 * プラン別機能-人事労務機能
 * @constructor
 */
const OfficialPlanMoreListWrap03 = (): JSX.Element => (
  <>
    <div className="ac-menu-inside">
      {/** 機能名 */}
      <div className="p-section-plan">
        <div className="p-function-wrap wrap-03">
          <CommonH3 label="人事労務機能" />
        </div>
        <div className="p-plan-list">
          <OfficialPlanItem label="社員基本情報設定" className="caution-item" />
          <OfficialPlanItem label="拡張情報設定" className="caution-item" />
          <OfficialPlanItem label="権限設定" className="caution-item" />
          <OfficialPlanItem label="休職設定" className="caution-item" />
          <OfficialPlanItem className="caution-item">
            <a href="#modal-bnr01">代理申請</a>
          </OfficialPlanItem>
          <OfficialPlanItem label="組織管理" className="caution-item" />
          <OfficialPlanItem label="お知らせ投稿" className="caution-item" />
          <OfficialPlanItem label="給与ソフト連携" className="caution-item" />
          <OfficialPlanItem label="有給自動付与" className="caution-item" />
          <OfficialPlanItem label="休暇付与" className="caution-item" />
          <OfficialPlanItem label="データ移行" className="caution-item" />
        </div>
      </div>
      {/** フリー */}
      <div className="p-section-plan plan03">
        <div className="p-plan-list mt-090">
          <PlanFunctionType info="一部制限有り" />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="最大5グループ" />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
        </div>
      </div>
      {/** ビジネス */}
      <div className="p-section-plan plan02">
        <div className="p-plan-list mt-090">
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="最大15グループ" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
        </div>
      </div>
      {/** エンタープライズ */}
      <div className="p-section-plan plan01">
        <div className="p-plan-list mt-090">
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
        </div>
      </div>
    </div>
  </>
)

export default OfficialPlanMoreListWrap03
