import React from 'react'
import CommonH3 from '../atoms/commonH3'
import OfficialPlanName from '../atoms/officialPlanIName'
import OfficialPlanItem from '../atoms/officialPlanItem'
import PlanFunctionType from '../atoms/planFunctionType'

/**
 * プラン別機能-基本機能
 * @constructor
 */
const OfficialPlanMoreListWrap01 = (): JSX.Element => (
  <>
    <div className="ac-menu-inside">
      {/** 機能名 */}
      <div className="p-section-plan">
        <div className="p-function-wrap wrap-02">
          <CommonH3 label="基本機能" />
        </div>
        <div className="p-plan-list">
          <OfficialPlanItem label="PC-WEB打刻" />
          <OfficialPlanItem label="スマホ-WEB打刻" />
          <OfficialPlanItem label="アプリ打刻" />
          <OfficialPlanItem label="打刻防止通知" />
          <OfficialPlanItem label="休憩の自動追加" className="caution-item" />
          <OfficialPlanItem label="勤務地変更" />
          <OfficialPlanItem label="残業申請" />
          <OfficialPlanItem label="休暇申請" />
          <OfficialPlanItem label="勤怠申請" />
          <OfficialPlanItem label="MTG申請" className="caution-item" />
          <OfficialPlanItem label="通勤交通費申請" className="caution-item" />
          <OfficialPlanItem label="シフト作成" />
          <OfficialPlanItem label="シフトマスタ設定" className="caution-item" />
          <OfficialPlanItem label="自社勤務表" />
          <OfficialPlanItem label="客先勤務表" className="caution-item" />
          <OfficialPlanItem label="勤怠アラート" className="caution-item" />
          <OfficialPlanItem label="勤務表PDF出力" />
          <OfficialPlanItem label="交通費精算" className="caution-item" />
          <OfficialPlanItem label="社員検索" />
          <OfficialPlanItem label="セルフアラート" className="caution-item" />
          <OfficialPlanItem label="管理アラート" className="caution-item" />
          <OfficialPlanItem
            label="Googleチャット通知"
            className="caution-item"
          />
        </div>
      </div>
      {/** フリー */}
      <div className="p-section-plan plan03">
        <OfficialPlanName label="Free" className="plan-free" />
        <div className="p-plan-list mt-040">
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="－" />
        </div>
      </div>
      {/** ビジネス */}
      <div className="p-section-plan plan02">
        <OfficialPlanName label="Business" className="plan-business" />
        <div className="p-plan-list mt-040">
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
        </div>
      </div>
      {/** エンタープライズ */}
      <div className="p-section-plan plan01">
        <OfficialPlanName label="Enterprise" className="plan-enterprise" />
        <div className="p-plan-list mt-040">
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
        </div>
      </div>
    </div>
  </>
)

export default OfficialPlanMoreListWrap01
