import React from 'react'
import CommonH3 from '../atoms/commonH3'
import PlanFunctionType from '../atoms/planFunctionType'
import OfficialPlanItem from '../atoms/officialPlanItem'

/**
 * プラン別機能-オプション
 * @constructor
 */
const OfficialPlanMoreListWrap06 = (): JSX.Element => (
  <>
    <div className="ac-menu-inside">
      {/** 機能名 */}
      <div className="p-section-plan">
        <div className="p-function-wrap wrap-03">
          <CommonH3 label="オプション機能" />
        </div>
        <div className="p-plan-list">
          <OfficialPlanItem className="caution-item h-60">
            <a href="#modal-bnr03">経費精算</a>
          </OfficialPlanItem>
          <OfficialPlanItem className="caution-item h-60">
            <a href="#modal-bnr02">案件管理機能</a>
          </OfficialPlanItem>
        </div>
      </div>
      {/** フリー */}
      <div className="p-section-plan plan03">
        <div className="p-plan-list mt-090">
          <PlanFunctionType className="h-60" info="－" />
          <PlanFunctionType className="h-60" info="－" />
        </div>
      </div>
      {/** ビジネス */}
      <div className="p-section-plan plan02">
        <div className="p-plan-list mt-090">
          <PlanFunctionType className="h-60" info="月額無料で利用可能">
            <div className="tooltip5">
              <img src="/images/info.png" alt="キンクラの経費精算機能" />
              <div className="description5">
                <img
                  src="/images/official/plan/ImgPlanOptionExpense001.png"
                  alt="キンクラの経費精算機能オプション料金"
                />
              </div>
            </div>
          </PlanFunctionType>
          <PlanFunctionType className="h-60" info="月額無料で利用可能">
            <div className="tooltip5">
              <img
                src="/images/info.png"
                alt="キンクラのSES案件管理機能オプション料金"
              />
              <div className="description5">
                <img
                  src="/images/official/plan/ImgPlanOptionProject001.png"
                  alt="キンクラのSES案件管理機能オプション料金"
                />
              </div>
            </div>
          </PlanFunctionType>
        </div>
      </div>
      {/** エンタープライズ */}
      <div className="p-section-plan plan01">
        <div className="p-plan-list mt-090">
          <PlanFunctionType className="h-60" info="利用可能">
            <div className="tooltip5">
              <img src="/images/info.png" alt="キンクラの経費精算機能" />
              <div className="description5">
                <img
                  src="/images/official/plan/ImgPlanOptionExpense001.png"
                  alt="キンクラの経費精算機能オプション料金"
                />
              </div>
            </div>
          </PlanFunctionType>
          <PlanFunctionType className="h-60" info="利用可能">
            <div className="tooltip5">
              <img
                src="/images/info.png"
                alt="キンクラのSES案件管理機能オプション料金"
              />
              <div className="description5">
                <img
                  src="/images/official/plan/ImgPlanOptionProject001.png"
                  alt="キンクラのSES案件管理機能オプション料金"
                />
              </div>
            </div>
          </PlanFunctionType>
        </div>
      </div>
    </div>
    {/* <div className="ac-menu-inside mb-020"> */}
    {/*   <div className="annotation-wrap"> */}
    {/*     <p> */}
    {/*       ※1 */}
    {/*       Businessプランでのご利用には月額10,000円(税抜)のオプション基本料金が発生します。詳細は */}
    {/*       <a */}
    {/*         href="https://develop.d6n6ncnzc2qmb.amplifyapp.com/function/stg/ses-management#ses-management-option-price" */}
    {/*         target="_blank" */}
    {/*         rel="noreferrer" */}
    {/*       > */}
    {/*         こちら */}
    {/*       </a> */}
    {/*       をご確認ください。 */}
    {/*     </p> */}
    {/*     <p> */}
    {/*       ※2 */}
    {/*       Enterpriseプランのオプション基本料金は無料ですが、利用状況に応じて追加料金が発生する可能性があります。詳細は */}
    {/*       <a */}
    {/*         href="https://develop.d6n6ncnzc2qmb.amplifyapp.com/function/stg/ses-management#ses-management-option-price" */}
    {/*         target="_blank" */}
    {/*         rel="noreferrer" */}
    {/*       > */}
    {/*         こちら */}
    {/*       </a> */}
    {/*       をご確認ください。 */}
    {/*     </p> */}
    {/*   </div> */}
    {/* </div> */}
  </>
)

export default OfficialPlanMoreListWrap06
