import React from 'react'
import CommonH3 from '../atoms/commonH3'
import OfficialPlanItem from '../atoms/officialPlanItem'
import PlanFunctionType from '../atoms/planFunctionType'

/**
 * プラン別機能-システム管理
 * @constructor
 */
const OfficialPlanMoreListWrap04 = (): JSX.Element => (
  <>
    <div className="ac-menu-inside">
      {/** 機能名 */}
      <div className="p-section-plan">
        <div className="p-function-wrap wrap-03">
          <CommonH3 label="システム管理" />
        </div>
        <div className="p-plan-list">
          <OfficialPlanItem label="企業設定" className="caution-item" />
          <OfficialPlanItem label="承認ルール設定" className="caution-item" />
          <OfficialPlanItem label="36協定設定" className="caution-item" />
          <OfficialPlanItem label="時間まるめ設定" className="caution-item" />
          <OfficialPlanItem label="有給休暇設定" className="caution-item" />
          <OfficialPlanItem label="特別休暇設定" className="caution-item" />
          <OfficialPlanItem label="祝日設定" className="caution-item" />
          <OfficialPlanItem label="勤務形態管理" className="caution-item" />
          <OfficialPlanItem label="役職管理" className="caution-item" />
          <OfficialPlanItem label="事業所管理" className="caution-item" />
          <OfficialPlanItem label="勤務地管理" className="caution-item" />
          <OfficialPlanItem label="アラート設定" className="caution-item" />
        </div>
      </div>
      {/** フリー */}
      <div className="p-section-plan plan03">
        <div className="p-plan-list mt-090">
          <PlanFunctionType info="一部制限有り" />
          <PlanFunctionType info="最大2種/2段階" />
          <PlanFunctionType info="基本設定のみ" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="最大8種" />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="最大3種" />
          <PlanFunctionType info="最大5種" />
          <PlanFunctionType info="本社のみ" />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="－" />
        </div>
      </div>
      {/** ビジネス */}
      <div className="p-section-plan plan02">
        <div className="p-plan-list mt-090">
          <PlanFunctionType mark />
          <PlanFunctionType info="最大3段階" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="最大4拠点" />
          <PlanFunctionType info="最大6地点" />
          <PlanFunctionType info="一部制限有り" />
        </div>
      </div>
      {/** エンタープライズ */}
      <div className="p-section-plan plan01">
        <div className="p-plan-list mt-090">
          <PlanFunctionType mark />
          <PlanFunctionType info="最大5段階" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
        </div>
      </div>
    </div>
  </>
)

export default OfficialPlanMoreListWrap04
