import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import { navigate } from 'gatsby-link'
import React from 'react'
import OfficialCommonBtn from '../components/atoms/officialCommonBtn'
import SEO from '../components/atoms/seo'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowHeader from '../components/organisms/officialLowHeader'
import OfficialLowPlan01 from '../components/organisms/officialLowPlan01'
import BreakPoints from '../styles/breakPoints'
import UpdateInfoContentV4 from '../components/atoms/updateInfoContentV4'
import UpdateInfoContentV3 from '../components/atoms/updateInfoContentV3'
import UpdateInfoContent from '../components/atoms/updateInfoContent'
import useRouteParam from '../hooks/useRouteParam'

const Wrapper = styled.main`
  .p-top-data-wrap {
    max-width: 1090px;
    border-radius: 8px;
    margin: -20px auto 60px;
    padding: 20px;
    text-align: center;
    position: relative;
    width: 90vw;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      left: 5%;
      top: 33px;
      border-radius: 2px;
      background: #3ec7b3;
      z-index: 0;
    }

    p {
      margin: 0 auto 20px;
      padding: 3px 10px;
      width: max-content;
      background: #f8f8f8;
      position: relative;
      z-index: 1;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-all;
    }

    button {
      margin: 0;
    }
  }

  .tooltip5 {
    position: relative;
    cursor: pointer;
    display: inline-block;
    z-index: 100;

    img {
      width: 20px;
    }

    .description5 {
      display: none;
      position: absolute;
      padding: 10px;
      font-size: 12px;
      line-height: 1.6em;
      color: #fff;
      border-radius: 5px;
      background: rgba(50, 50, 50, 0.9);
      width: auto;
      z-index: 200;

      img {
        width: 90vw;
        max-width: 700px;
      }
    }

    &:hover .description5 {
      display: inline-block;
      bottom: 20px;
      right: 0;
    }
  }

  .div-modal {
    &.modal-wrapper {
      .modal-window {
        padding-top: 0;

        .modal-content {
          display: flex;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            margin: 54px 0 0;
            border: solid 2px #cccccc;
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-top-data-wrap {
      p {
        width: 100%;
      }
    }
  }
`

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * 料金ページ[サービスサイト料金]
 * @constructor
 */
const PlanListPage = ({ location }: IProps) => {
  const routeParam = useRouteParam('/service-faq')
  return (
    <OfficialLayout location={location} className="p-page-plan">
      <SEO title="料金プラン" />
      <Wrapper>
        <OfficialLowHeader
          label="料金プラン"
          description="企業規模に合わせた3つの料金プランをご案内します"
        />
        <OfficialLowPlan01 />
        <div className="p-top-data-wrap">
          <p>
            お客様からよくお問い合わせいただくご質問を、Q&amp;A形式でご案内しています。
          </p>
          <OfficialCommonBtn
            size="large"
            level="weak"
            onClick={() => {
              navigate(routeParam)
            }}
          >
            よくあるご質問
          </OfficialCommonBtn>
        </div>
      </Wrapper>
      <UpdateInfoContentV4 idName="modal-bnr03" />
      <UpdateInfoContentV3 idName="modal-bnr02" />
      <UpdateInfoContent idName="modal-bnr01" />
    </OfficialLayout>
  )
}

export default PlanListPage

export const pageQuery = graphql`
  query PlanListPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
