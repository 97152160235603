import React from 'react'
import CommonH3 from '../atoms/commonH3'
import OfficialPlanItem from '../atoms/officialPlanItem'
import PlanFunctionType from '../atoms/planFunctionType'

/**
 * プラン別機能-サポート
 * @constructor
 */
const OfficialPlanMoreListWrap05 = (): JSX.Element => (
  <>
    <div className="ac-menu-inside mb-020">
      {/** 機能名 */}
      <div className="p-section-plan">
        <div className="p-function-wrap wrap-03">
          <CommonH3 label="サポート" />
        </div>
        <div className="p-plan-list">
          <OfficialPlanItem label="機能カスタマイズ" className="caution-item" />
          <OfficialPlanItem
            label="カスタマーサポート"
            className="caution-item"
          />
        </div>
      </div>

      {/** フリー */}
      <div className="p-section-plan plan03">
        <div className="p-plan-list mt-090">
          <PlanFunctionType info="－" />
          <PlanFunctionType info="メール" />
        </div>
      </div>

      {/** ビジネス */}
      <div className="p-section-plan plan02">
        <div className="p-plan-list mt-090">
          <PlanFunctionType info="－" />
          <PlanFunctionType info="メール" />
        </div>
      </div>

      {/** エンタープライズ */}
      <div className="p-section-plan plan01">
        <div className="p-plan-list mt-090">
          <PlanFunctionType mark />
          <PlanFunctionType info="メール" />
        </div>
      </div>
    </div>
  </>
)

export default OfficialPlanMoreListWrap05
