import React from 'react'
import CommonH3 from '../atoms/commonH3'
import OfficialPlanItem from '../atoms/officialPlanItem'
import PlanFunctionType from '../atoms/planFunctionType'

/**
 * プラン別機能-管理職機能
 * @constructor
 */
const OfficialPlanMoreListWrap02 = (): JSX.Element => (
  <>
    <div className="ac-menu-inside">
      {/** 機能名 */}
      <div className="p-section-plan">
        <div className="p-function-wrap wrap-03">
          <CommonH3 label="管理職機能" />
        </div>
        <div className="p-plan-list">
          <OfficialPlanItem label="提出状況確認" className="caution-item" />
          <OfficialPlanItem label="出勤者管理" className="caution-item" />
          <OfficialPlanItem label="打刻情報管理" className="caution-item" />
          <OfficialPlanItem label="有給管理" className="caution-item" />
          <OfficialPlanItem label="休暇の取得管理" className="caution-item" />
          <OfficialPlanItem label="休暇の残日数管理" className="caution-item" />
          <OfficialPlanItem label="残業警告" className="caution-item" />
          <OfficialPlanItem label="月間の残業管理" className="caution-item" />
          <OfficialPlanItem label="年間の残業管理" className="caution-item" />
          <OfficialPlanItem label="勤務実態集計" className="caution-item" />
          <OfficialPlanItem label="一覧カスタマイズ" className="caution-item" />
          <OfficialPlanItem label="一覧エクスポート" className="caution-item" />
          <OfficialPlanItem
            label="帳票出力/データエクスポート"
            className="caution-item"
          />
        </div>
      </div>

      {/** フリー */}
      <div className="p-section-plan plan03">
        <div className="p-plan-list mt-090">
          <PlanFunctionType info="最大3ヵ月を表示" />
          <PlanFunctionType info="当日分を表示" />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType info="最大3ヵ月を表示" />
          <PlanFunctionType info="最大2年分を表示" />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
          <PlanFunctionType info="－" />
          <PlanFunctionType mark />
        </div>
      </div>

      {/** ビジネス */}
      <div className="p-section-plan plan02">
        <div className="p-plan-list mt-090">
          <PlanFunctionType info="最大5年分を表示" />
          <PlanFunctionType info="最大5年分を表示" />
          <PlanFunctionType info="最大5年分を表示" />
          <PlanFunctionType mark />
          <PlanFunctionType info="最大5年分を表示" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType info="最大5年分を表示" />
          <PlanFunctionType info="最大6年分を表示" />
          <PlanFunctionType info="最大5年分を表示" />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
        </div>
      </div>

      {/** エンタープライズ */}
      <div className="p-section-plan plan01">
        <div className="p-plan-list mt-090">
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
          <PlanFunctionType mark />
        </div>
      </div>
    </div>
  </>
)

export default OfficialPlanMoreListWrap02
