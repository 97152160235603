import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialPlanMoreListWrap01 from './officialPlanIMoreListWrap01'
import OfficialPlanMoreListWrap02 from './officialPlanIMoreListWrap02'
import OfficialPlanMoreListWrap03 from './officialPlanIMoreListWrap03'
import OfficialPlanMoreListWrap04 from './officialPlanIMoreListWrap04'
import OfficialPlanMoreListWrap05 from './officialPlanIMoreListWrap05'
import OfficialPlanMoreListWrap06 from './officialPlanIMoreListWrap06'

const Wrapper = styled.div`
  width: 100%;

  .ac-menu input {
    display: none;
  }

  .ac-menu label {
    max-width: 640px;
    margin: 25px auto 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3ec7b3;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 25px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  .ac-menu label:after {
    position: absolute;
    right: 20px; /* 左からの距離 */
    top: 50%; /* 上下中央配置 */
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: '＋'; /* アイコン */
    font-size: 1.8em;
    font-weight: 300;
  }

  .ac-menu input:checked ~ label::after {
    content: '−'; /* クリック後のアイコン */
  }

  .ac-menu .ac-menu-inside {
    height: 0;
    opacity: 0;
    transition: 0.2s; /* 開閉スピード */

    .p-section-plan {
      display: none;
    }
  }

  .ac-menu input:checked ~ .ac-menu-inside {
    padding: 15px 0 0 0;
    height: auto;
    opacity: 1;

    .p-section-plan {
      display: block;
      width: 100%;
    }
  }

  .ac-menu input:checked ~ label {
    background: #fafafa;
    color: #cccccc;
    margin-bottom: 20px;
  }

  .ac-menu-inside {
    display: flex;
    justify-content: space-between;

    .p-section-plan {
      width: 100%;
    }
  }

  ${BreakPoints.xLarge} {
    .p-section-inner,
    .p-more-wrap {
      .p-section-plan {
        .p-function-wrap {
          h3 {
            span {
              &:before {
              }
            }
          }

          // 高さを合わせるためのdiv
          &.wrap-01 {
            height: 430px;
          }

          &.wrap-02 {
          }

          &.wrap-03 {
          }
        }

        &.plan03 {
          .price-txt {
            span {
            }
          }

          .txt-color-label {
          }
        }

        .p-plan-list {
        }

        &.plan01,
        &.plan02,
        &.plan03 {
          .p-plan-list {
          }
        }
      }
    }

    .p-more-wrap {
    }
  }

  ${BreakPoints.large} {
    .p-section-inner,
    .p-more-wrap {
      justify-content: center;

      .p-section-plan {
        .p-function-wrap {
          // 高さを合わせるためのdiv
          &.wrap-01 {
            width: 170px;
            height: 180px;
          }
        }

        &.plan01,
        &.plan02,
        &.plan03 {
          width: calc(90vw / 4) !important;

          .p-plan-list {
          }
        }
      }
    }

    .p-more-wrap {
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner,
    .p-more-wrap {
      justify-content: center;
      padding: 10px 10px 0;

      .p-section-plan {
        .p-function-wrap {
          // 高さを合わせるためのdiv
          &.wrap-01 {
            width: 100%;
            height: 180px;
          }
        }

        &.plan01,
        &.plan02,
        &.plan03 {
          width: calc(90vw / 4) !important;
        }
      }
    }

    .p-more-wrap {
    }
  }

  ${BreakPoints.small} {
    .p-section-inner,
    .p-more-wrap {
      justify-content: center;
      padding: 10px 10px 0;

      .p-section-plan {
        .p-function-wrap {
          // 高さを合わせるためのdiv
          &.wrap-01 {
            width: 100%;
            height: 150px;
          }
        }

        &.plan01,
        &.plan02,
        &.plan03 {
          width: calc(90vw / 4);
        }
      }
    }
  }
`

/**
 * プラン別機能をもっと見るコンポーネント
 * @constructor
 */
const OfficialPlanMoreList = (): JSX.Element => (
  <Wrapper>
    <div className="ac-menu">
      <input id="ac-1" type="checkbox" />
      <label htmlFor="ac-1">機能をすべて表示する</label>
      {/** 基本機能 */}
      <OfficialPlanMoreListWrap01 />
      {/** 管理職機能 */}
      <OfficialPlanMoreListWrap02 />
      {/** 人事労務機能 */}
      <OfficialPlanMoreListWrap03 />
      {/** システム管理 */}
      <OfficialPlanMoreListWrap04 />
      {/** サポート */}
      <OfficialPlanMoreListWrap05 />
      {/** オプション */}
      <OfficialPlanMoreListWrap06 />
    </div>
  </Wrapper>
)

export default OfficialPlanMoreList
