import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialPlanMoreListWrap06 from './officialPlanIMoreListWrap06'

const Wrapper = styled.div`
  width: 100%;

  .ac-menu-inside {
    display: flex;
    justify-content: center;

    .wrap-03 {
      width: 230px;
    }

    .p-section-plan {
      //width: 100%;
      //max-width: 230px;

      &.plan01,
      &.plan02,
      &.plan03 {
        max-width: unset;
        width: 100%;
      }
    }
  }

  ${BreakPoints.xLarge} {
    .p-section-inner,
    .p-more-wrap {
      .p-section-plan {
        .p-function-wrap {
          h3 {
            span {
              &:before {
              }
            }
          }

          // 高さを合わせるためのdiv
          &.wrap-01 {
            height: 430px;
          }

          &.wrap-02 {
          }

          &.wrap-03 {
          }
        }

        &.plan03 {
          .price-txt {
            span {
            }
          }

          .txt-color-label {
          }
        }

        .p-plan-list {
        }

        &.plan01,
        &.plan02,
        &.plan03 {
          .p-plan-list {
          }
        }
      }
    }

    .p-more-wrap {
    }
  }

  ${BreakPoints.large} {
    .ac-menu-inside {
      // 高さを合わせるためのdiv
      .wrap-03 {
        width: 170px;
        height: 180px;
      }

      .p-more-wrap {
        justify-content: center;
      }

      .p-more-wrap {
      }
    }

    .plan01,
    .plan02,
    .plan03 {
      max-width: calc(90vw / 4) !important;

      .p-plan-list {
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner,
    .p-more-wrap {
      justify-content: center;
      //padding: 10px 10px 0;

      .p-section-plan {
        .p-function-wrap {
          // 高さを合わせるためのdiv
        }
      }
    }

    .wrap-03 {
      min-width: 110px !important;
      width: calc(90vw / 4) !important;
      height: 180px;
    }

    .plan01,
    .plan02,
    .plan03 {
      max-width: calc(90vw / 4) !important;
    }

    .p-more-wrap {
    }

    .h-60 {
      height: 60px;
      line-height: 14px;
    }
  }

  ${BreakPoints.small} {
    .p-section-inner,
    .p-more-wrap {
      justify-content: center;
      padding: 10px 10px 0;

      .p-section-plan {
        .p-function-wrap {
          // 高さを合わせるためのdiv
          &.wrap-01 {
            width: 100%;
            height: 150px;
          }
        }
      }
    }

    .plan01,
    .plan02,
    .plan03 {
      max-width: calc(90vw / 4);
    }
  }
`

/**
 * プラン一覧オプション表示
 * @constructor
 */
const OfficialPlanOptionList = (): JSX.Element => (
  <Wrapper>
    {/** オプション */}
    <OfficialPlanMoreListWrap06 />
  </Wrapper>
)

export default OfficialPlanOptionList
