import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  text-align: center;
  padding: 20px;
  height: 310px;

  .price-txt {
    font-weight: 600;
    font-size: 44px;

    span {
      font-size: 24px;

      &.sub-txt {
        font-size: 14px;
        padding: 0 5px;
      }
    }
  }

  .txt-color-label {
    width: 100%;
    margin: 0 auto 15px;
    color: #272d2c;
    font-size: 14px;
    font-weight: 500;
    height: 50px;
    border-bottom: solid 1px #cdd6dd;

    &.narrow-only {
      display: none;
      border: none;
      height: 15px;
    }
  }

  .number-txt {
    font-weight: 600;
    font-size: 16px;
  }

  .detail-txt {
    max-width: 268px;
    min-height: 156px;
    margin: 20px 5px 0;
    text-align: left;
    white-space: pre-wrap;
    line-height: 1.8;
  }

  ${BreakPoints.xLarge} {
    padding: 10px;
    height: 370px;

    .detail-txt {
      height: 100px;
    }

    @media screen and (max-width: 920px) {
      .price-txt {
        span {
          &.sub-txt {
            display: none;
          }
        }
      }

      .txt-color-label {
        &.narrow-only {
          display: block;
        }

        > span {
          display: none;
        }
      }
    }
  }

  ${BreakPoints.large} {
    height: 120px;

    .txt-color-label {
      border-bottom: none;
    }

    .price-txt {
      font-size: 30px;

      span {
        font-size: 16px;
      }
    }

    .detail-txt {
      display: none;
    }
  }

  ${BreakPoints.medium} {
    padding: 10px 5px;
  }

  ${BreakPoints.small} {
    height: 90px;

    .price-txt {
      font-size: 18px;

      span {
        font-size: 10px;
      }
    }

    .txt-color-label {
      font-size: 10px;

      &.narrow-only {
        height: 5px;
      }
    }
  }
`

type Props = {
  /** 金額 */
  price: string
  /** 概要 */
  description?: string
}

/**
 * フリー、ビジネス情報概要コンポーネント
 * @param props
 * @constructor
 */
const OfficialPlanDetail = (props: Props): JSX.Element => (
  <Wrapper>
    <p className="price-txt">
      <span className="sub-txt">月額</span>
      {props.price}
      <span>円</span>
      <span className="sub-txt">(税抜)</span>
    </p>
    <p className="txt-color-label narrow-only">月額（税抜）</p>
    <p className="txt-color-label">
      （<span>最低利用料：</span>4,500円~）
    </p>
    <p className="detail-txt">{props.description}</p>
  </Wrapper>
)

export default OfficialPlanDetail
